import React, {useEffect} from "react"
import { Link, graphql, withPrefix } from "gatsby"
import parse from "html-react-parser"
import Helmet from "react-helmet"

const BlogPostTemplate = ({ data: { post, allWpPost } }) => {

  const featuredImage = {
    data: post.seo?.opengraphImage?.mediaItemUrl || `${process.env.GATSBY_SITE_URL}/default-image.png`,
    alt: post.title || `${process.env.GATSBY_SITE_NAME}`,
  }

  const authorPost = {
    name: post.author?.node?.name || ``,
    slug: post.author?.node?.slug || ``,
  }
  
  const formatDateTime = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    
    return `${day} tháng ${month} năm ${year}`;
  }

  const generateLinkImage = (url) => {
    if(url) {
      return url.replace(`${process.env.GATSBY_SITE_SOURCE_IMAGE}`, `${process.env.GATSBY_SITE_CDN_IMAGE}`)
    } else {
      return `${process.env.GATSBY_SITE_URL}/default-image.png`
    }
  }

  useEffect(() => {
    document.getElementsByTagName("noscript")[1].remove()
  }, [])

  return (
    <>
      <Helmet>
        <script src={withPrefix("script.js")} type="text/javascript" />
      </Helmet>
      <div className="category-page__header container">
        <div className="category-page__header__breadcrumb">
          <div className="category-page__header__breadcrumb__container">
            <Link className="category-page__header__breadcrumb__link" to="/">Trang chủ</Link>
            <i className="fa-solid fa-angle-right category-page__header__breadcrumb__separator"></i>
            <Link className="category-page__header__breadcrumb__link" to={`/${post?.categories?.nodes[0]?.slug}/ `}>{post?.categories?.nodes[0]?.name}</Link>
            {/* <i className="fa-solid fa-angle-right category-page__header__breadcrumb__separator"></i>
            <p className="category-page__header__breadcrumb__title">{parse(post?.title)}</p> */}
          </div>
        </div>
      </div>

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
        style={{maxWidth: "720px", margin: "auto"}}
      >
        <div className="pagedetail__header">
          
          <h1 className="pagedetail__header--title" itemProp="headline">{parse(post.title)}</h1>

          <div className="pagedetail__header--desc" dangerouslySetInnerHTML={{ __html: post.excerpt}}></div>

          <div className="pagedetail__header--up">
            <p className="pagedetail__header--up-time" >{`Đã cập nhật ${formatDateTime(post.date)}`}</p>
            <p className="pagedetail__header--up-by" >Bởi {`${authorPost.name}`}</p>
          </div>
          <div className="pagedetail__header--share">
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_SITE_URL}${post.uri}%3Futm_source%3Dfacebook%26utm_medium%3Dsocial%26utm_campaign%3Dshareurlbuttons`} rel="noopener noreferrer nofollow" target="_blank" className="pagedetail__header--share-btn btn-share-primary">
              <i className="fa-brands fa-facebook-f"></i>
              <span className="pagedetail__header--share-">
                Chia sẻ
              </span>
            </a>
            <a href={`http://www.pinterest.com/pin/create/button/?url=${process.env.GATSBY_SITE_URL}${post.uri}%3Futm_source%3Dpinterest%26utm_medium%3Dsocial%26utm_campaign%3Dshareurlbuttons&description=${parse(post.title)}&media=${post?.seo?.opengraphImage?.mediaItemUrl || `${process.env.GATSBY_SITE_URL}/default-image.png`}`} rel="noopener noreferrer nofollow" target="_blank" className="pagedetail__header--share-btn btn-share-danger">
              <i className="fa-brands fa-pinterest"></i>
              <span className="pagedetail__header--share-">
                Lưu
              </span>
            </a>
            <a href={`https://api.whatsapp.com/send?text=${parse(post.title)} ${process.env.GATSBY_SITE_URL}${post.uri}%3Futm_source%3DWhatsApp%26utm_medium%3Dsocial%26utm_campaign%3Dshareurlbuttons`} rel="noopener noreferrer nofollow" target="_blank" className="pagedetail__header--share-btn btn-share-success">
              <i className="fa-brands fa-whatsapp"></i>
              <span className="pagedetail__header--share-">
                Chia sẻ
              </span>
            </a>
          </div>
        </div>

        <div className="pagedetail__banner">
          <div className="pagedetail__banner--img">
            {featuredImage?.data && (
              <img 
                src={generateLinkImage(featuredImage.data)} 
                alt={featuredImage.alt || `${process.env.GATSBY_SITE_NAME}`} 
                placeholder="blurred" 
                // loading="lazy" 
              />
            )}
          </div>
        </div>

        {!!post.content && (
          <section style={{maxWidth: "720px", margin: "auto"}} className="pagedetail__content" itemProp="articleBody" dangerouslySetInnerHTML={{ __html: post.content}}></section>
        )}

        <div className="pagedetail__tag">
          <p className="pagedetail__tag--title">Tags:</p>
          {post?.tags?.nodes?.map((tag, index) => (<Link key={index} to={`/tag/${tag.slug}/ `} className="pagedetail__tag--item">{tag.name}</Link>))}
        </div>
      </article>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByIdAMPPage(
    $id: String!
    $categoryId: String!
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      uri
      seo {
        opengraphImage {
          mediaItemUrl
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories {
        nodes {
          id
          slug
          name
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      seo {
        metaDesc
        title
        canonical
        breadcrumbs {
          text
          url
        }
        opengraphImage {
          mediaItemUrl
        }
      }
    }
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {id: { eq: $categoryId }}}}}
      limit: 8
    ) {
      edges {
        node {
          title
          uri
          seo {
            opengraphImage {
              mediaItemUrl
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`
